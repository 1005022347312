.Section {
	&___margined {
		margin-top: #{var(--spacing--lg)};
		margin-bottom: #{var(--spacing--lg)};
	}

	&___centered {
		text-align: center;
	}
}
